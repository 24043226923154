// FILMS TYPES
export const GET_TOP_RATED = "GET_TOP_RATED";
export const GET_TOP_RATED_ERROR = "GET_TOP_RATED_ERROR";

export const GET_DISCOVER = "GET_DISCOVER";
export const GET_DISCOVER_ERROR = "GET_DISCOVER_ERROR";

export const GET_SEARCH_LIST = "GET_SEARCH_LIST";
export const GET_SEARCH_LIST_ERROR = "GET_SEARCH_LIST_ERROR";

export const GET_BY_ID = "GET_BY_ID";
export const GET_BY_ID_ERROR = "GET_BY_ID_ERROR";

export const GET_ALL_FILM = "GET_ALL_FILM";
export const GET_ALL_FILM_ERROR = "GET_ALL_FILM_ERROR";


// SERIES TYPES
export const GET_SERIES_POPULAR = "GET_SERIES_POPULAR";
export const GET_SERIES_POPULAR_ERROR = "GET_SERIES_POPULAR_ERROR";