import Typography from "@mui/material/Typography";

export default function Footer() {
  return (
    <footer>
      <Typography
        sx={{
          color: "gray",
        }}
      >
        @ 2022 Kusy. All rights reserved
      </Typography>
    </footer>
  );
}
